/**
* @constructor
* Implements a minimal drop-down navigation menu with hamburger menu on mobile / tablet
*
* @param {Element} menu The menu element.
*/

export default function A11yMenu(menu) {
	// Define value for keycodes
	const VK_ESC = 27;

	this.container = menu.parentElement; // The nav element
	this.trigger = this.container.querySelector('.a11y-nav-trigger'); // the hamburger button
	this.currentMenuItem = false;
	this.firstFocusableElement = "";
	this.lastFocusableElement = "";

	// Initialisation
	this.init = () => {
		this.menuSetup();
	};

	// Open / Close main Nav
	this.toggleNavContainer = () => {

		if (this.trigger.getAttribute('aria-expanded') === 'false') {
			// open the nav, focus on the nav
			this.setupMainFocusableTrap();

			document.documentElement.setAttribute('data-burger-menu-status', 'opened');
			this.trigger.setAttribute('aria-expanded', true);
			menu.setAttribute('aria-hidden', false);
			menu.focus();

		} else {
			// hide, focus on the trigger
			this.trigger.setAttribute('aria-expanded', false);
			menu.setAttribute('aria-hidden', true);
			document.documentElement.setAttribute('data-burger-menu-status', 'closed');

			this.trigger.focus();
		}

	};

	this.closeNav = () => {

		this.trigger.setAttribute('aria-expanded', false);
		menu.setAttribute('aria-hidden', true);
		document.body.setAttribute('data-burger-menu-status', 'closed');
		this.trigger.focus();

	};

	this.keyboardManager = (e) => {
		// Check for TAB key press
		if (e.keyCode === 9) {

			// SHIFT + TAB
			if (e.shiftKey) {

				if (document.activeElement === this.firstFocusableElement && this.trigger.getAttribute('aria-expanded') == 'true') {
					e.preventDefault();
					this.lastFocusableElement.focus();
				}

				// TAB
			} else {
				if (document.activeElement === this.lastFocusableElement && this.trigger.getAttribute('aria-expanded') == 'true') {
					e.preventDefault();
					this.firstFocusableElement.focus();
				}
			}
		}

		// ESCAPE
		if (e.keyCode === VK_ESC) {
			this.closeNav();
		}

	};



	this.menuSetup = () => {
		this.checkIfMobileNav();
		// Check if menu is hidden and trigger visible : then add Aria to the menu
		if (this.isEltHidden(menu)) {
			this.setUpNavContainerAria();
		}

		// Bind event listener
		this.trigger.addEventListener('click', this.toggleNavContainer);

		// Bind keyboard listener to nav
		this.container.addEventListener('keydown', this.keyboardManager.bind(this));

		// Bind click listener to close nav
		menu.querySelector('.a11y-nav-close').addEventListener('click', this.closeNav);

		// Setup focus trap
		this.setupMainFocusableTrap();
		
	};


	this.setupMainFocusableTrap = () => {
		const focusableElements = menu.querySelectorAll('a, button');

		this.firstFocusableElement = focusableElements[0];
		this.lastFocusableElement = focusableElements[focusableElements.length - 1];

		// console.log(this.firstFocusableElement);
		// console.log(this.lastFocusableElement);
	};

	this.setUpNavContainerAria = () => {
		this.trigger.setAttribute('aria-expanded', false);
		menu.setAttribute('aria-hidden', true);
	};

	this.checkIfMobileNav = () => {
		window.addEventListener('resize', () => {
			if (window.matchMedia('(max-width: 40em)').matches) {

				// Switch to mobile menu
				this.trigger.setAttribute('aria-expanded', false);
				menu.setAttribute('aria-hidden', true);

			} else {

				// Switch to desktop
				this.trigger.setAttribute('aria-expanded', true);
				menu.setAttribute('aria-hidden', false);

			}
		});
	};


	// Private functions
	this.isEltHidden = (elem) => {
		const styles = window.getComputedStyle(elem);
		return (styles.display === 'none' || styles.visibility === 'hidden');
	};
}