import A11yMenu from './_a11yMenu';
import Disclosure from './_disclosure';
import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';

function initDisclo() {
	// Disclosure
	const disclos = document.querySelectorAll('.disclosure > button');
	disclos.forEach((btn) => {
		const discloItem = new Disclosure(btn);
		discloItem.init();
	});
}

document.addEventListener('DOMContentLoaded', () => {

	document.body.classList.remove('no-js');
	document.body.classList.add('js');

	const a11yMenus = document.querySelectorAll('.a11y-nav');
	a11yMenus.forEach((menu) => {
		const a11yMenuItem = new A11yMenu(menu);
		a11yMenuItem.init();
	});

	initDisclo();

	const tables = document.querySelectorAll('.datatable');
	tables.forEach((elt) => {
		let if_order = true;
		if (elt.classList.contains('no-order')) {
			if_order = false;
		}
		
		new DataTable(elt, {
			paging: false,
			scrollY: 600,
			scrollCollapse: true,
			searching: false,
			ordering: if_order,
			"info": false,
			responsive: {
				breakpoints: [
					{ name: 'desktop', width: Infinity },
					{ name: 'tablet-l', width: 1024 },
					{ name: 'tablet-p', width: 768 },
					{ name: 'mobile-l', width: 480 },
					{ name: 'mobile-p', width: 420 }
				]
			}
		});
	});

});